import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';

import Section from '@narative/gatsby-theme-novela/src/components/Section';
import SocialLinks from '@narative/gatsby-theme-novela/src/components/SocialLinks';

import mediaqueries from '@narative/gatsby-theme-novela/src/styles/media';

const siteQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            name
            social {
              url
              name
            }
          }
        }
      }
    }
    # allMdx(
    #   sort: { fields: frontmatter___date, order: ASC }
    #   filter: { frontmatter: { date: { ne: null } } }
    # ) {
    #   edges {
    #     node {
    #       frontmatter {
    #         date
    #       }
    #     }
    #   }
    # }
  }
`;

const Footer: React.FC<{}> = () => {
  const results = useStaticQuery(siteQuery);
  const { name, social } = results.allSite.edges[0].node.siteMetadata;

  // const copyrightDate = (() => {
  //   const { edges } = results.allMdx;
  //   const years = [0, edges.length - 1].map((edge) =>
  //     new Date(edges[edge].node.frontmatter.date).getFullYear()
  //   );
  //   return years[0] === years[1] ? `${years[0]}` : `${years[0]}–${years[1]}`;
  // })();
  const siteName = 'OPTML';
  const copyrightDate = '2020';

  return (
    <>
      {/* <FooterGradient /> */}
      <Section narrow>
        <HoritzontalRule />
        <FooterContainer>
          <FooterText>
            <h3>SESSIONS BY APPOINTMENT ONLY</h3>
            <h3>(email us for more information!)</h3>
            <br />
            <h3>OPTML (@ HACKD Fitness)</h3>
            <p>226 Seventh Avenue</p>
            <p>New York, NY 10011</p>
            <br />
            <p>info@optml.co</p>
            <br />
            <p>
              © {copyrightDate} {siteName}
            </p>
          </FooterText>
          <div>
            <SocialLinks links={social} />
          </div>
        </FooterContainer>
      </Section>
      <div id="footer" />
    </>
  );
};

export default Footer;

const FooterContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 80px;
  color: ${(p) => p.theme.colors.grey};

  ${mediaqueries.tablet`
    flex-direction: column;
    padding-bottom: 100px;
  `}

  ${mediaqueries.phablet`
    padding-bottom: 50px;
  `}
`;

const HoritzontalRule = styled.div`
  position: relative;
  margin: 140px auto 50px;
  border-bottom: 1px solid ${(p) => p.theme.colors.horizontalRule};

  ${mediaqueries.tablet`
    margin: 60px auto;
  `}

  ${mediaqueries.phablet`
    display: none;
  `}
`;

const FooterText = styled.div`
  ${mediaqueries.tablet`
    margin-bottom: 80px;
  `}

  ${mediaqueries.phablet`
    margin: 120px auto 100px;
  `}
`;

// const FooterGradient = styled.div`
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 590px;
//   z-index: 0;
//   pointer-events: none;
//   background: ${(p) => p.theme.colors.gradient};
//   transition: ${(p) => p.theme.colorModeTransition};
// `;
