import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property vallue called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */
export default function Logo({ fill }) {
  return (
    // <svg viewBox="0 0 106 28" height="30px">
    //   <path d="M62.9 12h2.8v10...." />
    //   <path fill={fill} d="M25 14h-..." />
    //   <path d="M14 0C6.3..." fill="#639" />
    // </svg>

    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="150.000000pt"
      height="33.000000pt"
      viewBox="0 0 150.000000 33.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,33.000000) scale(0.020000,-0.020000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M490 1273 c-694 -230 -558 -1258 167 -1261 437 -1 709 320 640 755
   -60 381 -450 625 -807 506z m356 -221 c311 -133 309 -659 -2 -797 -400 -177
   -741 290 -492 672 89 135 329 196 494 125z"
        />
        <path
          d="M1435 1268 c-8 -8 -15 -288 -15 -621 l0 -607 120 0 120 0 0 180 0
   180 215 0 c255 0 342 21 437 104 234 206 192 577 -82 722 -83 44 -758 80 -795
   42z m665 -234 c147 -50 180 -260 58 -363 -57 -48 -74 -51 -280 -51 l-218 0 0
   220 0 220 185 -1 c102 -1 217 -12 255 -25z"
        />
        <path
          d="M2500 1170 l0 -110 200 0 200 0 0 -510 0 -510 120 0 120 0 0 510 0
   510 190 0 190 0 0 110 0 110 -510 0 -510 0 0 -110z"
        />
        <path
          d="M3620 660 l0 -620 110 0 109 0 6 445 5 444 195 -264 c107 -146 208
   -265 223 -265 15 0 116 119 225 264 l197 264 5 -444 6 -444 109 0 110 0 0 620
   0 620 -128 0 -127 0 -196 -270 c-108 -149 -202 -264 -209 -255 -8 8 -97 130
   -199 270 l-185 255 -128 0 -128 0 0 -620z"
        />
        <path
          d="M5100 660 l0 -620 430 0 430 0 0 110 0 110 -320 0 -320 0 0 510 0
   510 -110 0 -110 0 0 -620z"
        />
      </g>
    </svg>
  );
}
